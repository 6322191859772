<template>
<div>
    <b-card>
        <!-- input search -->
        <div class="custom-search ">
            <b-form-group>
                <!-- Accept all image formats by IANA media type wildcard-->
                <label for="wildcard">Accept CSV File</label>
                <b-form-file id="wildcard" accept=".csv" v-model="file" />
                <div class="d-flex" style="margin-top:20px">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1" @click="processAirtimeUpload">
                        Upload File
                    </b-button>
                </div>

            </b-form-group>
        </div>
    </b-card>
    <b-card>
        <!-- input search -->

        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex">
                    <label class="mr-1">Search</label>
                    <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                     
                     <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"  variant="primary" class="mr-1" >
                        <feather-icon icon="DownloadIcon" size="17" class="cursor-pointer" />
                </b-button>
                </div>
               
            </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
            enabled: true,
            externalQuery: searchTerm }" :pagination-options="{
            enabled: true,
            perPage:pageLength
          }">
            <template slot="table-row" slot-scope="props">

                <!-- Column: Name -->
                <div v-if="props.column.field === 'network_name'" class="text-nowrap">

                    <span class="text-nowrap">{{ props.row.network_name }}</span>

                </div>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="props.row.state | shortCodeStatusColor">
                        {{ props.row.state | rateCardStatus}}
                    </b-badge>
                    

                </span>
                <span v-else-if="props.column.field === 'Source'">

                    <p>
                        {{ props.row.client_name }}
                        <small>IP: {{props.row.source_ip}}</small>
                    </p>
                </span>

                <span v-else-if="props.column.field === 'utility_amount'">
                    {{ props.row.currency_iso}} {{ props.row.utility_amount | formatAmount}}

                </span>
               

                <span v-else-if="props.column.field === 'networks'">
                    SAFARICOM: {{ JSON.parse(props.row.networks).SAFARICOM }}

                </span>
                <span v-else-if="props.column.field === 'created_at'">
                    {{ props.row.created_at | formatDate}}

                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                 <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="props.row.state ==1" variant="success" @click="downloadTransaction(props.row.bulk_utilityId)">
                        <feather-icon icon="DownloadIcon" class="mr-50" />

                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="props.row.state ==2" variant="success" @click="actionUpdate(props.row.bulk_utilityId)">
                        <feather-icon icon="CheckIcon" class="mr-50" />

                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="props.row.state ==2" variant="primary" @click="actionReject(props.row.bulk_utilityId)">
                        <feather-icon icon="ArchiveIcon" class="mr-50" />

                    </b-button>
                </span>
                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap "> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>

                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <b-modal ref="my-modal-cancel" title="Reject Request" ok-title="Confirm & Reject" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleReject" v-show="showModal">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group :state="nameState">
                    <div class="d-flex justify-content-between">
                        <label for="login-password">Auth Code</label>
                    </div>
                    <b-input-group class="input-group-merge">
                        <b-form-input id="login-password" v-model="pinCode" :state="nameState" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············" required />
                        <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

            </form>
        </b-modal>
        <b-modal ref="my-modal" title="Confirm By Entering Authorization Code sent Via SMS" ok-title="Confirm & Approve" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleOk" v-show="showModal">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group :state="nameState">
                    <div class="d-flex justify-content-between">
                        <label for="login-password">Auth Code</label>
                    </div>
                    <b-input-group class="input-group-merge">
                        <b-form-input id="login-password" v-model="pinCode" :state="nameState" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············" required />
                        <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

            </form>
        </b-modal>
    </b-card>
</div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BModal,
    VBModal,
    BListGroup,
} from 'bootstrap-vue'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'
import {
    VueGoodTable
} from 'vue-good-table'
import {
    BFormFile
} from 'bootstrap-vue'
import store from '@/store/index'
import $ from 'jquery'
import numeral from "numeral"
import moment from "moment"
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BCard,
        BFormFile,
        BButton,
        BModal,
        VBModal,
        BListGroup,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            pinCode: "",
            nameState: null,
            submittedNames: "",
            name: "",
            utilityID: "",
            file: null,
            pageLength: 8,
            dir: false,
            showModal: false,
            columns: [{
                    label: 'Utitlity ID',
                    field: 'bulk_utilityId',
                },
                {
                    label: 'Utility',
                    field: 'utility',
                },
                {
                    label: 'Source',
                    field: 'Source',
                },
                {
                    label: 'Recipients',
                    field: 'number_of_recipients',
                },
                {
                    label: 'Cost',
                    field: 'utility_amount',
                },
                {
                    label: 'Networks',
                    field: 'networks',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Date',
                    field: 'date'
                },
                {
                    label: 'Action',
                    field: 'action'
                }
            ],
            rows: [],
            searchTerm: '',
            status: [{
                13: 'Active',
                11: 'Pending',
                12: 'Rejected',
            }, {
                13: 'light-success',
                11: 'light-primary',
                12: 'light-danger',
            }],
        }
    },
    directives: {
        Ripple,
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Active: 'light-success',
                Pending: 'light-primary',
                Rejected: 'light-danger',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    mounted() {
        let vm = this
        vm.getTransactions()

    },
    methods: {

        actionUpdate(utilityID) {
            let vm = this
            vm.utilityID = utilityID
            this.$refs['my-modal'].show()
        },
        actionReject(utilityID) {
            let vm = this
            vm.utilityID = utilityID
            this.$refs['my-modal-cancel'].show()
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleReject(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmitReject()
        },
        confirmAccount() {

        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.pinCode = ''
            this.nameState = null
        },
        handleSubmitReject(){
            console.log("Pin " + this.pinCode + " Utility ID " + this.utilityID)
            let vm = this
            $.post({
                url: store.state.rootUrl + 'bill/v1/approval',
                post: "POST",
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    status: 3,
                    authCode: vm.pinCode,
                    transactionId: vm.utilityID
                }),
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        });

                    } else {
                        vm.getTransactions()
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                        vm.$nextTick(() => {
                            vm.$refs['my-modal-cancel'].toggle('#toggle-btn')
                        })
                        
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: statustext,
                            icon: 'EditIcon',
                            variant: 'failed',
                        },
                    });
                }
            });
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            console.log("Pin " + this.pinCode + " Utility ID " + this.utilityID)
            let vm = this
            $.post({
                url: store.state.rootUrl + 'bill/v1/approval',
                post: "POST",
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    status: 1,
                    authCode: vm.pinCode,
                    transactionId: vm.utilityID
                }),
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    $('#addUser').html(' Add');
                    if (response.data.code != 200) {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        });

                    } else {
                        vm.getTransactions()
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                        vm.$nextTick(() => {
                            vm.$refs['my-modal'].toggle('#toggle-btn')
                        })
                        
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: statustext,
                            icon: 'EditIcon',
                            variant: 'failed',
                        },
                    });
                }
            });
        },
        processAirtimeUpload() {
            let vm = this
            var data = new FormData();
            //data.append("clientId", vm.$cookies.get("clientData").clientId);
            data.append("countryCode", "254");
            data.append("callbackUrl", "");
            data.append("uniqueId", new Date().getTime());
            data.append("uploadedFile", vm.file);
            $.ajax({
                url: store.state.rootUrl + 'bill/v1/bulk_airtime',
                type: "POST",
                data: data,
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                cache: false,
                contentType: false,
                processData: false,

                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                        vm.rows = []
                       

                    } else {
                         vm.getTransactions()
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isloading = false
                    vm.dismissCountDown = vm.dismissSecs
                    vm.file == null
                    if (jQxhr.responseJSON.code == "Error") {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.statusDescription,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }
                }
            });
        },
        getTransactions() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/utilities',
                type: "get",
                async: true,
                data: {
                    offset: "",
                    sort: "",
                    export: 1,
                    limit: "",
                    start: "",
                    end: "",
                    clientId: vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {
                    if (response.data.code != 200) {
                        vm.rows = []
                    } else {
                        let result = response.data.data.data;
                        vm.rows = result

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.rows = []
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({
                            name: 'login'
                        })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }
                }
            });
        },
         downloadTransaction(utilityID) {
            let vm = this
            console.log("am here")
            vm.isLoading = true
            $('#loadingDownload').html(' Please Wait ...');
            $.get({
                //https://app.apiproxy.co/account/v1/view/utility/transactions/6?sort&offset&limit&export&status=&amount&recipients&clientId&start&end&ipAddress&ipApproval&uniqueId&accountNumber&utilityBulkId&statusDesc
                 url: store.state.rootUrl + 'account/v1/view/utility/transactions/6',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    utilityBulkId: utilityID,
                    export: 1,
                },
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {
                       vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'error',
                            },
                        })

                    } else {
                        $('#loadingDownload').html(' Converting the document');
                        var exportRecipients = [];
                        for (var i = 0; i < response.data.data.data.length; i++) {
                            var item = {};
                            item.account_number = response.data.data.data[i].account_number;
                            item.amount = response.data.data.data[i].amount;
                            item.utility_name = response.data.data.data[i].utility_name;
                            item.network = response.data.data.data[i].network;
                            item.status_description = response.data.data.data[i].status_description;
                            item.created_at = response.data.data.data[i].created_at;
                            exportRecipients.push(item);
                        }
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                        vm.JSONToCSVConvertor(exportRecipients, "AirtimeReport_"+exportDate, 1);
                        $('#loadingDownload').html(' Please wait...');
                        vm.isLoading = false
                         vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    var statustext = jQxhr.responseJSON.statusDescription;
                    var errorMessage = ""
                    if (!statustext) {
                        errorMessage = "An error occurred. Try again later.";
                    } else {
                        var messageError = ""
                        messageError = jQxhr.responseJSON.data.message
                        if (!messageError) {
                            errorMessage = statustext;
                        } else {
                            errorMessage = statustext + " " + messageError;
                        }

                    }
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorMessage,
                            icon: 'EditIcon',
                            variant: 'error',
                        },
                    })
                }
            });
        },
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "Liden_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                link.remove();
        },

    },
    filters: {
        formatAmount: function (value) {
            if (value <= 0) {
                return 0.00
            }
            return numeral(value).format("0,0.00");
        },
        formatNumber: function (value) {
            if (value <= 0) {
                return 0
            }
            return numeral(value).format("0,0");
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        rateCardStatus(value) {
            if (value == '2') {
                return "Pending Approval"
            } else if (value == '1') {
                return "Success"
            } else {
                return "Rejected"
            }
        },
        shortCodeStatusColor(value) {
            if (value == '1') {
                return "light-success"
            } else if (value == '2') {
                return "light-warning"
            } else {
                return "light-danger"
            }
        }
    }

}
</script>
